import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadState: 1,
  resultsForDownload: null,
  resultsReceived: false,
  uploadedFileName: "",
  smartIDData: null,
  ieJobId: null,
};

const smartIDSlice = createSlice({
  name: "smartID",
  initialState,
  reducers: {
    setUploadStateSmartID: (state, action) => {
      state.uploadState = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setSmartIDData: (state, action) => {
      state.smartIDData = action.payload;
    },
    setJobIdSmartID: (state, action) => {
      state.ieJobId = action.payload;
    },
  },
});

export const {
  setUploadStateSmartID,
  setResultsForDownload,
  setResultsReceived,
  setUploadedFileName,
  setSmartIDData,
  setJobIdSmartID,
} = smartIDSlice.actions;

export default smartIDSlice.reducer;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  adapters: [],
  status: "idle",
  error: null,
};

const adaptersSlice = createSlice({
  name: "adapters",
  initialState,
  reducers: {
    setAdapters(state, action) {
      state.adapters = action.payload;
    },
    adapterAdded(state, action) {
      state.adapters.push(action.payload);
    },
    adaptersUpdated(state, action) {
      const { id, updatedAdapter } = action.payload;
      let existingAdapter = state.adapters.find(
        (adapters) => adapters.id === id
      );
      if (existingAdapter) {
        existingAdapter = updatedAdapter;
      }
    },
    adapterDeleted(state, action) {
      const id = action.payload;
      state.adapters = state.adapters.filter((adapter) => adapter.id !== id);
    },
    resetAdapters(state) {
      state.adapters = [];
    }

  },
});

export const {
  setAdapters,
  adapterAdded,
  adapterDeleted,
  resetAdapters,
} = adaptersSlice.actions;

export default adaptersSlice.reducer;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadState: 1,
  resultsForDownload: null,
  resultsReceived: false,
  uploadedFileName: "",
  ledgerData: {},
  ledgerJobId: null,
};

const smartLedgerSlice = createSlice({
  name: "smartLedger",
  initialState,
  reducers: {
    setUploadStateSmartLedger: (state, action) => {
      state.uploadState = action.payload;
    },
    setResultsForDownloadLedger: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setResultsReceivedLedger: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setUploadedFileNameLedger: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setSmartLedgerData: (state, action) => {
      state.ledgerData = action.payload;
    },
    setLedgerJobId: (state, action) => {
      state.ledgerJobId = action.payload;
    },
  },
});

export const {
  setUploadStateSmartLedger,
  setResultsForDownloadLedger,
  setResultsReceivedLedger,
  setUploadedFileNameLedger,
  setSmartLedgerData,
  setLedgerJobId,
} = smartLedgerSlice.actions;

export default smartLedgerSlice.reducer;

import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import packageVersion from "../../../package.json";

function Footer() {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className={"fixed text-[#8B8B8B] bg-[#404040] bottom-0 w-full z-[100]"}
    >
      <div className="grid grid-cols-2 py-3">
        <div className="mobile:pl-4 pl-12  my-auto lg:text-sm text-xs">
          smartTools Version {packageVersion.version}
        </div>
        <div className="mobile:pr-4 pr-12  my-auto ml-auto lg:text-sm text-xs">
          {`${t("Footer_Copyright").replace("2025", currentYear)}`}
        </div>
      </div>
    </footer>
  );
}

export default withTranslation()(Footer);

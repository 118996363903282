import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoutesContainer from "./components/app/Routes";
import Loading from "./pages/Loading";
import Sidebar from "./components/base/Sidebar";
import Header from "./components/base/Header";
import Breadcrumbs from "./components/base/Breadcrumbs";
import NotificationBox from "./components/laigo/NotificationBox";
import Footer from "./components/base/Footer";
import InitialOverlay from "./components/laigo/InitialOverlay";
import { useLocation } from "react-router-dom";
import { ensureCustomer } from "./store/helpers/subscription-actions";
import { Toaster } from "./components/laigo/Shad/toaster";
import KeycloakService from "./pages/KeycloakService";
import { logout } from "./store/AuthSlice";
import { TooltipProvider } from "./components/UI/Tooltip"; // Import TooltipProvider

const fallback = <Loading />;

export default function App() {
  const location = useLocation();
  const dispatch = useDispatch();

  const storeAuthStatus = useSelector((state) => state.authStatus.isLoggedIn);
  const sidebarOpen = useSelector((state) => state.defaultSettings.sidebarOpen);
  const resourceAccess = useSelector(
    (state) => state.authStatus.resourceAccess
  );
  const keycloak = useSelector((state) => state.authStatus.user?.tokenParsed);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const validateAuth = async () => {
      try {
        setIsLoading(true);
        const keycloakAuth = await KeycloakService.isLoggedIn();

        const isValid = storeAuthStatus && keycloakAuth;
        setIsAuthenticated(isValid);

        if (storeAuthStatus && !keycloakAuth) {
          console.warn("Authentication state mismatch detected");
          dispatch(logout());
          await KeycloakService.doLogout();
        }
      } catch (error) {
        console.error("Auth validation failed:", error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    validateAuth();

    // return () => clearInterval(interval);
  }, [dispatch, storeAuthStatus]);

  useEffect(() => {
    const ensureCustomerCalled = localStorage.getItem("ensureCustomerCalled");

    if (keycloak?.sub && !ensureCustomerCalled) {
      dispatch(ensureCustomer(keycloak.sub));
      localStorage.setItem("ensureCustomerCalled", "true");
    }
  }, [dispatch, keycloak]);

  if (isLoading) return fallback;

  const isRegisterPage = /^\/register/.test(location.pathname);

  return (
    <TooltipProvider delayDuration={0}>
      <div>
        <Suspense fallback={fallback}>
          <div
            className={`flex ${sidebarOpen ? "sidebarOpen" : "sidebarClosed"} ${
              isRegisterPage ? "mb-0" : "appContainer h-max mb-16"
            }`}
          >
            {isAuthenticated && (
              <>
                <InitialOverlay />
                <Header />
                <Sidebar />
              </>
            )}
            <Toaster />

            <div
              className={`flex flex-col flex-grow overflow-hidden z-10 ${
                isRegisterPage ? "register-page-container" : "contentArea"
              }`}
            >
              <NotificationBox />
              {isAuthenticated && <Breadcrumbs />}
              <div
                className={`${
                  !isRegisterPage &&
                  "mt-[90px] mobile:mx-0 lg:ml-2 md:ml-10 lg:mr-12 lg:rounded-lg"
                }`}
              >
                <RoutesContainer
                  isAuth={isAuthenticated}
                  resourceAccess={resourceAccess}
                />
              </div>
            </div>
          </div>
          {isAuthenticated && <Footer />}
        </Suspense>
      </div>
    </TooltipProvider>
  );
}
